// src/components/VendorListForm.js
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { quickBooksService } from '../services/quickBooksService';

function VendorListForm({ open, onClose, onSelect }) {
  const [vendorName, setVendorName] = useState('');
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await quickBooksService.searchVendors(vendorName);
      setVendors(result);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
    setLoading(false);
  };

  const handleSelect = (vendor) => {
    onSelect(vendor);
    onClose(); // Close the form after selecting a vendor
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        Select Vendor
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
          <TextField
            label="Vendor Name"
            fullWidth
            value={vendorName}
            onChange={(e) => setVendorName(e.target.value)}
            placeholder="Enter vendor name"
          />
          <Button variant="contained" onClick={handleSearch} disabled={loading}>
            Search
          </Button>
        </Box>
        <List>
          {vendors.length > 0 ? (
            vendors.map((vendor) => (
              <ListItem
                button
                key={vendor.Id}
                onClick={() => handleSelect(vendor)}
              >
                <ListItemText primary={vendor.DisplayName} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No vendors found" />
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default VendorListForm;

