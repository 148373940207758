// src/services/docuForceService.js
import axios from 'axios';

const API_URL = 'https://api.docuforce.infologistics.co.za/orgs/c8d55549-92ee-4ace-bd82-ddf623c5dc41';

const API_KEY = '7eDcZfwl+4b5ad05e-2473-469d-be31-1c6449d9e391:67f5a929-b021-4314-a586-0900174f61d6'; // Replace with your actual API key

axios.interceptors.request.use((config) => {
  console.log('Request:', config);
  return config;
});

export const docuForceService = {
  getDocuments: async (dateFrom, dateTo, pageNumber = 1) => {
    const fromTimestamp = new Date(dateFrom).getTime();
    const toTimestamp = new Date(dateTo).getTime();
    
    const response = await axios.get(
      `${API_URL}/documents`,
      {
        headers: {
          'X-API-Key': API_KEY,
        },
        params: {
          'DateOfPayment.ge': fromTimestamp,
          'DateOfPayment.le': toTimestamp,
          'flowStageUserName.in': 'Accounting',
          page: pageNumber,
          'perPage' : 10
        },
      }
    );
    return response.data;
  },

  getContractor: async (contractorOguid) => {
    const response = await axios.get(
      `${API_URL}/contractors/${contractorOguid}`,
      {
        headers: {
          'X-API-Key': API_KEY,
        },
      }
    );
    return response.data;
  },

  updateContractor: async (contractorOguid, vendorId) => {
    await axios.put(
      `${API_URL}/contractors/${contractorOguid}`,
      {
        externalKey: vendorId,
        isValidated: true,
      },
      {
        headers: {
          'X-API-Key': API_KEY,
        },
      }
    );
  },
};

