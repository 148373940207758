// src/services/quickBooksService.js
import axios from 'axios';
// import qs from 'qs';

const API_URL = 'https://qb.dev.infologistics.co.za/api/quickbooks'; // Proxy endpoint

axios.interceptors.request.use((config) => {
  console.log('Request:', config);
  return config;
});

export const quickBooksService = {
  login: async () => {
    // Implement OAuth2 login flow
    window.location.href = 'https://qb.dev.infologistics.co.za/api/quickbooks/login';
  },

  checkAuthorization: async () => {
    const response = await axios.get(`${API_URL}/isAuthorized`);
    return response.data.authorized;
  },
  
  checkBillExists: async (docNumber, sumTotal) => {
    const query = `SELECT * FROM Bill WHERE DocNumber='${docNumber}' AND TotalAmt='${sumTotal}'`;
    const response = await axios.post(
      `${API_URL}/query`,
      { query },
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
    return response.data.QueryResponse.Bill && response.data.QueryResponse.Bill.length > 0;
  },

  getVendor: async (vendorId) => {
    const response = await axios.get(`${API_URL}/vendor/${vendorId}`);
    return response.data;
  },

  createBill: async (billData) => {
    await axios.post(`${API_URL}/bill`, billData);
  },

  searchVendors: async (vendorName) => {
    const query = `SELECT * FROM Vendor WHERE DisplayName LIKE '%${vendorName}%'`;
    const response = await axios.post(
      `${API_URL}/query`,
      { query },
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    );
    return response.data.QueryResponse.Vendor || [];
  },
};

