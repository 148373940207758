// src/components/MainForm.js
import React, { useState,useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  // Typography,
  CircularProgress,
} from '@mui/material';
import DocumentTable from './DocumentTable';
import TransferStatusForm from './TransferStatusForm';
import { docuForceService } from '../services/docuForceService';
import { quickBooksService } from '../services/quickBooksService';

function MainForm() {
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1); // Track the current page
  const [qbLoggedIn, setQbLoggedIn] = useState(false);
  const [openTransferForm, setOpenTransferForm] = useState(false);

  useEffect(() => {
    // Check if QuickBooks is already authorized when the component mounts
    quickBooksService.checkAuthorization().then((isAuthorized) => {
      setQbLoggedIn(isAuthorized);
    });
  }, []);
  
  const fetchDocuments = async (page = 1) => {
    setLoading(true);
    try {
      const docs = await docuForceService.getDocuments(dateFrom, dateTo, page);
      setDocuments(docs);
      setPageNumber(page); // Set the current page after fetching data
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const handleShow = () => {
    fetchDocuments(1); // Start at page 1
  };

  const handlePageChange = (event, newPage) => {
    fetchDocuments(newPage);
  };

  const handleLoginQuickBooks = () => {
    quickBooksService.login().then(() => {
      setQbLoggedIn(true);
    });
  };

  const handleTransfer = () => {
    if (selectedDocs.length === 0) {
      alert('Please select documents to transfer.');
      return;
    }
    setOpenTransferForm(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(0,0,0,0.5)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={2}>
          <TextField
            label="Date From"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={dateFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
          <TextField
            label="Date To"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
          />
          <Button variant="contained" onClick={handleShow}>
            Show
          </Button>
          <Button variant="contained" onClick={handleTransfer}>
            Transfer Selected
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={handleLoginQuickBooks}
          disabled={qbLoggedIn}
        >
          Login to QuickBooks
        </Button>
      </Box>
      <DocumentTable
        documents={documents}
        selectedDocs={selectedDocs}
        setSelectedDocs={setSelectedDocs}
        page={pageNumber}
        onPageChange={handlePageChange}
      />
      {openTransferForm && (
        <TransferStatusForm
          open={openTransferForm}
          onClose={() => setOpenTransferForm(false)}
          documents={selectedDocs}
        />
      )}
    </Box>
  );
}

export default MainForm;

