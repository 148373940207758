// src/components/TransferStatusForm.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Button,
  Box,
} from '@mui/material';
import VendorListForm from './VendorListForm';
import { docuForceService } from '../services/docuForceService';
import { quickBooksService } from '../services/quickBooksService';

function TransferStatusForm({ open, onClose, documents }) {
  const [transferDocs, setTransferDocs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);

  useEffect(() => {
    const initDocs = documents.map((doc) => ({
      ...doc,
      vendorStatus: '',
      vendorName: '',
      transferStatus: '',
      errorExplanation: '',
    }));
    setTransferDocs(initDocs);
    setSelectedDocs(initDocs);
    startTransfer(initDocs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTransfer = async (docs) => {
    for (let doc of docs) {
      await processDocument(doc);
    }
  };

  const processDocument = async (doc) => {
    // Create a copy to avoid mutating state directly
    const docCopy = { ...doc };
    docCopy.transferStatus = 'Processing';
    updateDoc(docCopy);

    try {
      // Step 1: Check if bill exists
      const billExists = await quickBooksService.checkBillExists(
        doc.fields.documentNumber,
        doc.fields.sumTotal
      );
      if (billExists) {
        docCopy.transferStatus = 'Error';
        docCopy.errorExplanation = 'Bill already exists';
        updateDoc(docCopy);
        return;
      }

      // Step 2: Check if vendor exists
      const contractor = await docuForceService.getContractor(
        doc.fields.contractor.oguid
      );
      const vendorRef = contractor.externalKey;
      if (!vendorRef) {
        docCopy.vendorStatus = 'Not detected';
        docCopy.transferStatus = 'Error';
        docCopy.errorExplanation = 'Vendor not detected';
        updateDoc(docCopy);
        return;
      } else {
        docCopy.vendorStatus = 'Detected';
        const vendor = await quickBooksService.getVendor(vendorRef);
        docCopy.vendorName = vendor.DisplayName;
      }

      // Step 3: Create Bill
      const billData = {
        VendorRef: {
          value: vendorRef,
        },
        TxnDate: new Date(doc.fields.documentDate).toISOString().split('T')[0],
        DueDate: new Date(doc.fields.DueDate).toISOString().split('T')[0],
        DocNumber: doc.fields.documentNumber,
        Line: [
          {
            DetailType: 'AccountBasedExpenseLineDetail',
            Amount: doc.fields.sumTotal,
            Description: doc.fields.LineDescription,
            AccountBasedExpenseLineDetail: {
              AccountRef: {
                value: '7', // Adjust as needed
              },
            },
          },
        ],
      };
      await quickBooksService.createBill(billData);
      docCopy.transferStatus = 'Success';
      updateDoc(docCopy);
    } catch (error) {
      docCopy.transferStatus = 'Error';
      docCopy.errorExplanation = error.message;
      updateDoc(docCopy);
    }
  };

  const updateDoc = (updatedDoc) => {
    setTransferDocs((prevDocs) =>
      prevDocs.map((doc) =>
        doc.fields.documentNumber === updatedDoc.fields.documentNumber
          ? updatedDoc
          : doc
      )
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedDocs(transferDocs);
    } else {
      setSelectedDocs([]);
    }
  };

  const handleClick = (event, doc) => {
    const selectedIndex = selectedDocs.findIndex(
      (d) => d.fields.documentNumber === doc.fields.documentNumber
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDocs, doc);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDocs.slice(1));
    } else if (selectedIndex === selectedDocs.length - 1) {
      newSelected = newSelected.concat(selectedDocs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDocs.slice(0, selectedIndex),
        selectedDocs.slice(selectedIndex + 1)
      );
    }
    setSelectedDocs(newSelected);
  };

  const isSelected = (doc) => {
    return selectedDocs.some(
      (d) => d.fields.documentNumber === doc.fields.documentNumber
    );
  };

  const handleRepeatTransfer = () => {
    const errorDocs = transferDocs.filter(
      (doc) => doc.transferStatus === 'Error' && isSelected(doc)
    );
    startTransfer(errorDocs);
  };

  const handleVendorFix = (doc) => {
    setCurrentDoc(doc);
    setVendorDialogOpen(true);
  };

  const handleVendorSelect = async (vendor) => {
    if (currentDoc) {
      try {
        await docuForceService.updateContractor(
          currentDoc.fields.contractor.oguid,
          vendor.Id
        );
        const updatedDoc = {
          ...currentDoc,
          vendorStatus: 'Detected',
          vendorName: vendor.DisplayName,
          transferStatus: '',
          errorExplanation: '',
        };
        updateDoc(updatedDoc);
        setVendorDialogOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getRowStyle = (doc) => {
    if (doc.transferStatus === 'Error') {
      return { backgroundColor: 'rgba(255,0,0,0.2)' };
    } else if (doc.transferStatus === 'Success') {
      return { backgroundColor: 'rgba(0,255,0,0.2)' };
    } else if (doc.transferStatus === 'Processing') {
      return { backgroundColor: 'rgba(255,255,0,0.2)' };
    } else {
      return {};
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        Status of transferring documents into QuickBooks
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead style={{ backgroundColor: '#f0f0f0' }}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedDocs.length > 0 &&
                    selectedDocs.length < transferDocs.length
                  }
                  checked={
                    transferDocs.length > 0 &&
                    selectedDocs.length === transferDocs.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Document Date</TableCell>
              <TableCell>Document Number</TableCell>
              <TableCell>Sum Total</TableCell>
              <TableCell>Contractor Name</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transferDocs.map((doc, index) => {
              const isItemSelected = isSelected(doc);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  style={getRowStyle(doc)}
                  key={doc.fields.documentNumber}
                  onClick={(event) => handleClick(event, doc)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(doc.fields.documentDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{doc.fields.documentNumber}</TableCell>
                  <TableCell>{doc.fields.sumTotal}</TableCell>
                  <TableCell>{doc.fields.contractor.nameShort}</TableCell>
                  <TableCell>
                    <div>
                      {doc.vendorStatus === 'Not detected' ? (
                        <Button
                          onClick={() => handleVendorFix(doc)}
                          variant="text"
                        >
                          Not detected
                        </Button>
                      ) : (
                        doc.vendorStatus
                      )}
                    </div>
                    <div>{doc.vendorName}</div>
                  </TableCell>
                  <TableCell>
                    <div>{doc.transferStatus}</div>
                    <div>{doc.errorExplanation}</div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
          <Button variant="contained" onClick={handleRepeatTransfer}>
            Repeat Transfer
          </Button>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </DialogContent>
      {vendorDialogOpen && (
        <VendorListForm
          open={vendorDialogOpen}
          onClose={() => setVendorDialogOpen(false)}
          onSelect={handleVendorSelect}
        />
      )}
    </Dialog>
  );
}

export default TransferStatusForm;

