// src/App.js
import React from 'react';
import MainForm from './components/MainForm';

function App() {
  return (
    <div>
      <MainForm />
    </div>
  );
}

export default App;

