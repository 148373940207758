// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
}

.container {
  padding: 20px;
}

.button {
  margin-right: 10px;
}

.table-header {
  background-color: #f5f5f5;
}

.table-row:hover {
  background-color: #e0e0e0;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.dialog-content {
  position: fixed;
  background: white;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  top: 20%;
  left: 0;
  right: 0;
}

`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,QAAQ;EACR,OAAO;EACP,QAAQ;AACV","sourcesContent":["/* General Styles */\nbody {\n  font-family: 'Roboto', sans-serif;\n}\n\n.container {\n  padding: 20px;\n}\n\n.button {\n  margin-right: 10px;\n}\n\n.table-header {\n  background-color: #f5f5f5;\n}\n\n.table-row:hover {\n  background-color: #e0e0e0;\n}\n\n.dialog-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0,0,0,0.5);\n}\n\n.dialog-content {\n  position: fixed;\n  background: white;\n  padding: 20px;\n  max-width: 600px;\n  margin: auto;\n  top: 20%;\n  left: 0;\n  right: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
