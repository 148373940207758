// src/components/DocumentTable.js
import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  TablePagination,
} from '@mui/material';

function DocumentTable({ documents, selectedDocs, setSelectedDocs, page, onPageChange }) {
  const rowsPerPage = 10;

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((doc) => doc);
      setSelectedDocs(newSelecteds);
      return;
    }
    setSelectedDocs([]);
  };

  const handleClick = (event, doc) => {
    const selectedIndex = selectedDocs.indexOf(doc);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDocs, doc);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDocs.slice(1));
    } else if (selectedIndex === selectedDocs.length - 1) {
      newSelected = newSelected.concat(selectedDocs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDocs.slice(0, selectedIndex),
        selectedDocs.slice(selectedIndex + 1)
      );
    }
    setSelectedDocs(newSelected);
  };

  const isSelected = (doc) => selectedDocs.indexOf(doc) !== -1;

  return (
    <>
      <Table>
        <TableHead style={{ backgroundColor: '#f0f0f0' }}>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  selectedDocs.length > 0 &&
                  selectedDocs.length < documents.length
                }
                checked={
                  documents.length > 0 &&
                  selectedDocs.length === documents.length
                }
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>Document Date</TableCell>
            <TableCell>Document Number</TableCell>
            <TableCell>Sum Total</TableCell>
            <TableCell>Contractor Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((doc, index) => {
            const isItemSelected = isSelected(doc);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, doc)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={doc.fields.documentNumber}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell>
                  {new Date(doc.fields.documentDate).toLocaleDateString()}
                </TableCell>
                <TableCell>{doc.fields.documentNumber}</TableCell>
                <TableCell>{doc.fields.sumTotal}</TableCell>
                <TableCell>{doc.fields.contractor.nameShort}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={-1} // Set to -1 if the total count is unknown
        page={page - 1} // TablePagination expects zero-based index
        onPageChange={(e, newPage) => onPageChange(e, newPage + 1)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </>
  );
}

export default DocumentTable;
